import React from "react"
import { Typography } from "components/typography"
import { Link, graphql, useStaticQuery } from "gatsby"
import { getArticleUrlFromTitle } from "components/utils"

const Articles = (props: { itemsPerPage?: number; page?: number; noHeading?: boolean }) => {
  const itemsPerPage = props.itemsPerPage || 10
  const page = props.page || 1
  const queryResult = useStaticQuery(graphql`
    {
      allContentfulArticle(filter: { title: { nin: ["DO NOT DELETE", ""] } }, sort: { createdAt: DESC }) {
        nodes {
          title
          lastUpdate
          author {
            name
          }
          faq {
            faq
          }
          createdAt
          seo {
            description
            title
          }
          relatedArticles {
            raw
          }
          longText {
            longText
          }
          readingTime
          summary
          updatedAt
          reference {
            internal {
              content
            }
          }
        }
      }
    }
  `)
  const articles: any[] = queryResult.allContentfulArticle.nodes
  return (
    <div style={{ marginTop: props.noHeading ? "50px" : "150px" }}>
      {!props.noHeading && (
        <div style={{ marginBottom: "50px" }} id="articles-and-guides">
          <Typography.H2>Latest News & Articles</Typography.H2>
        </div>
      )}
      {articles.slice((page - 1) * itemsPerPage, Math.min(articles.length, page * itemsPerPage)).map((article: any, idx: number) => (
        <div style={{ marginTop: "35px", lineHeight: "1.1" }} key={`article-${idx}`}>
          <Link to={"/" + getArticleUrlFromTitle(article.title)} style={{ fontSize: "20px", fontFamily: "Arboria-Bold,sans-serif", color: "#4f8ccc", textDecoration: "inherit" }}>
            {article.title}
          </Link>
          <Typography.P style={{ marginTop: "10px" }}>{article.summary}</Typography.P>
        </div>
      ))}
    </div>
  )
}

export default Articles
