import Layout from "components/layout"
import React from "react"
import { Typography } from "components/typography"
import Articles from "components/content/articles"

const NewsAndGuides = () => {
  return (
    <Layout title={`News and Guides | Stay or Go`} description="Compare term deposit offers from all the major banks and popular brands." image="/video/StayOrGo_Animation_01.jpf" urlSlug="term-deposits" includeMozoDisclaimer>
      <div className="container-center">
        <div className="home-container">
          <div>
            <Typography.H1 style={{ marginTop: "60px" }}>Latest News & Guides</Typography.H1>
            {/* <div style={{ marginTop: "30px" }}>
              <Typography.P comp="large">{description}</Typography.P>
            </div> */}
            <Articles noHeading />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsAndGuides
